import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
// import Layout from './../components/common/Layout'
import Root from './../components/common/Root'
import Header from './../components/common/Header'
import Modal from './../components/common/Modal'
import HomepageHero from './../components/page-components/HomepageHero'

export default function Home({ location }) {
  const [emailSubmitted, setEmailSubmitted] = useState(location.search == '?thanks' ? true : false);
  return (
    <Root>
      <Helmet>
        <title>Bitcoin Alliance</title>
        <meta name="description" content="Bitcoin Alliance" />
        {/*  */}
        <script type="text/javascript" async src="https://www.googletagmanager.com/gtag/js?id=G-WMJ3Y8WYJM"></script>
      </Helmet>
      <Header 
        hero={
          <HomepageHero/>
        }
      />
      <Modal show={emailSubmitted} />
    </Root>
  )
}
