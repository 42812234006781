import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { uiConstants } from './../../../utils/ui-constants'
import { breakpoints } from './../../../utils/theme'
import Button from './../common/Button'
import Layout from './../common/Layout'
import HeroForm from './HeroForm'
import SupportersList from './SupportersList'
import SponsorsList from './SponsorsList'
import HomepageHeroGraphicMobile from './../../images/hero-graphic-mobile.svg'
import WavesBottom from './../../images/waves.gif'

const HomepageHero = (props) => { 
    const [language, setLanguage] = useState(
        props.location.pathname.split("/")[1]
    );   
    return (
        <StyledHomepageHero>
            <Layout width="main-width">
                <div className="hero-content" data-aos="fade-up">
                    <SupportersList />
                    <div className="date-header-text">
                    {uiConstants[language].HomepageHeroContent.dateHeader}
                    </div>
                    <h1>{uiConstants[language].HomepageHeroOfficial.title}</h1>
                    <p className="sub-heading">
                        {uiConstants[language].HomepageHeroOfficial.subHeader}
                    </p>
                    <SponsorsList />
                    {/* <HeroForm /> */}
                    <div className="hero-ctas">
                        <Button 
                            id="register-now" 
                            type="secondary"
                            to="https://bitcoin-bankathon.devpost.com"
                            external
                            elementTag="a"
                            label={uiConstants[language].HomepageHeroContent.ctaRegisterNow}
                        />
                        <Button 
                            id="join-our-discord" 
                            type={"outline"} 
                            elementTag="a"
                            to={'https://discord.gg/Ryh7tuMUnf'} external
                            label={uiConstants[language].HomepageHeroContent.ctaJoinDiscord}
                        />
                    </div>
                </div>

                <div data-aos='fade-up' className="hero-image"></div>

                <div data-aos='fade-up' 
                    className="hero-animation-container"
                >
                </div>
                <div id="animation_container" data-aos="fade" style={{
                    backgroundColor: 'rgba(255, 255, 255, 1.00)',
                    width: '1100px',
                    height: '1012px'
                }}>
                    <canvas
                        id="canvas"
                        width="2200"
                        height="1012"
                        style={{
                            position: 'absolute',
                            display: 'block',
                            backgroundColor: 'rgba(255, 255, 255, 1.00)'
                        }}
                    />
                    <div
                        id="dom_overlay_container"
                        style={{
                            pointerEvents: 'none',
                            overflow: 'hidden',
                            width: '2200px',
                            height: '1012px',
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                            display: 'block'
                        }}
                    />
                </div>
            </Layout>
        </StyledHomepageHero>
    )
}

const StyledHomepageHero = styled(Layout)`
    position: relative;
    padding: 40px 0 0;
    margin-bottom: 80px;
    min-height: 720px;
    .hero-content {
        position: relative;
        z-index: 1;
        max-width: 971px;
        margin: 0 auto;
        .date-header-text {
            br {
                display: none;
            }
            margin-bottom: 12px;
            color: white;
            font-size: 32px;
            font-weight: 900;
            -webkit-text-stroke: 1px rgba(0, 106, 255, 1);
            filter: drop-shadow(-2px 2px 0 rgba(0, 106, 255, 1));
        }
        .hero-title-link {
            h1 {
                transition: 0.2s ease-in-out opacity;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        h1 {
            font-size: 89px;
            font-weight: 900;
            color: black;
            line-height: 86px;
            max-width: 982px;
            color: rgba(28, 51, 84, 1);
        }
        .sub-heading {
            max-width: 680px;
            margin-top: 24px;
            font-size: 28px;
            font-weight: 500;
            color: gray;
            line-height: 42px;
            color: rgba(28, 51, 84, 1);
        }
        .hero-ctas {
            display: flex;
            margin-top: 80px;
            div:first-child {
                margin-right: 16px;
            }
        }
    }
    .hero-logos-list {
        display: flex;
        align-items: center;
    }
    #animation_container {
        max-width: 50vw;
        top: -100px;
        right: -120px;
        position: absolute;
        mix-blend-mode: multiply;
        pointer-events: none;
        transform: scale(0.8) translateY(-40px) translateX(-180px);
    }
    .hero-image {
        display: none;
        position: absolute;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .hero-image-bottom {
        width: 694px;
        height: 100px;
        position: absolute;
        bottom: 30px;
        left: 0;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center left;
        background-image: url(${WavesBottom});
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
    }
    @media screen and (max-width: ${breakpoints.xl}) {
        padding-top: 48px;
        .hero-content {
            padding: 0px;
        }
        #animation_container {
            transform: scale(0.8) translateY(-30px);
        }
    }
    @media screen and (max-width: ${breakpoints.l}) {
        padding-top: 26px;
        #animation_container {
            transform: scale(0.75) translateY(-46px) translateX(-140px);
        }
    }
    @media screen and (max-width: ${breakpoints.sl}) {
        padding-top: 20px;
        .hero-content {
            h1 {
                font-size: 67px;
                line-height: 75px;
            }
        }
    }
    @media screen and (max-width: ${breakpoints.md}) {
        .hero-content {
            padding: 0px;
            .date-header-text {
                font-size: 26px;
                line-height: 32px;
                br {
                    display: unset;
                }
                .hide-mobile {
                    display: none;
                }
            }
            h1 {
                font-size: 63px;
            }
            .sub-heading {
                margin-top: 16px;
                max-width: 470px;
            }
            .hero-ctas {
                margin-top: 28px;
                flex-direction: column;
                .button {
                    width: 177px;

                    &:first-child {
                        margin-bottom: 24px;
                    }
                }
            }
        }
        #animation_container {
            display: none;
        }
        .hero-image {
            width: 500px;
            height: 500px;
            right: 0px;
            top: -200px;
            display: unset;
            background-position: right center;
            background-image: url(${HomepageHeroGraphicMobile});
        }
        .hero-image-bottom {
            bottom: 260px;
            left: -50px;
        }
    }
    @media screen and (max-width: 1259px) {
        .hero-content {
            .sub-heading {
                font-size: 16px;
                margin-top: 16px;
                line-height: 24px;
            }
        }
        .hero-image {
            right: -100px;
        }
    }
    @media screen and (max-width: ${breakpoints.s}) {
        padding: 20px 0 0;
        .hero-content {
            h1 {
                font-size: 39px;
                line-height: 47px;
            }
            .sub-heading {
                max-width: 470px;
            }
            .date-header-text {
                font-size: 22px;
                line-height: 26px;
                filter: drop-shadow(-1px 1px 0 rgba(0, 106, 255, 1));
                br {
                    display: unset;
                }
                .hide-mobile {
                    display: none;
                }
            }
        }
        .hero-image {
            width: 300px;
            height: 300px;
            right: -60px;
            top: -80px;
            display: unset;
            background-position: right center;
            background-image: url(${HomepageHeroGraphicMobile});
        }
        .hero-image-bottom {
            bottom: 90px;
            left: -200px;
        }
    }
    @media screen and (max-width: ${breakpoints.xs}) {
       .hero-content {
            .sub-heading {
                span {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
`;

export default props => (
    <Location>
        {locationProps => <HomepageHero {...locationProps} {...props} />}
    </Location>
)
