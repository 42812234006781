import React, {useEffect, useState} from "react"
import styled, { keyframes } from 'styled-components'
import { Location } from '@reach/router'
import { uiConstants } from './../../../utils/ui-constants'
import { breakpoints } from './../../../utils/theme'
import Button from './Button'

const Modal = (props) => {
    const [show, setShow] = useState(props.show);
    const [language, setLanguage] = useState(
        props.location.pathname.split("/")[1]
    );  
  return (
    show && (
        <StyledModal >
            <div className="modal-underlay"/>
            <div className="modal-window" data-aos="fade-up" data-aos-delay={500}>
                <h3>{uiConstants[language].EmailSuccessModalContent.title}</h3>
                <p>{uiConstants[language].EmailSuccessModalContent.content}</p>
                <Button 
                    type={'secondary'} 
                    label={uiConstants[language].EmailSuccessModalContent.button.label} 
                    to={uiConstants[language].EmailSuccessModalContent.button.to}
                />
            </div>
        {props.children}
        </StyledModal>
    )
  )
}

const fade = keyframes`
    0% {opacity: 0};
    100% {opacity: 0.5};
`;


const StyledModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-underlay {
        background: rgba(113, 127, 153, 1);
        width: inherit;
        height: inherit;
        opacity: 0.5;
        position: absolute;
        z-index: 0;
        opacity: 0;
        animation: ${fade} forwards 0.5s 1s;
    }
    .modal-window {
        background: white;
        position: relative;
        z-index: 1;
        color: black;
        max-width: 680px;
        padding: 80px 56px;
        text-align: center;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 3px 6px -4px rgba(1, 0, 45, 0.05);
        box-shadow: 0px 6px 16px 0px rgba(1, 0, 45, 0.08);
        box-shadow: 0px 9px 28px 8px rgba(1, 0, 45, 0.05);
        h3 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            text-transform: uppercase;
            color: rgba(28, 51, 84, 1);
        }
        p {
            margin-top: 16px;
            font-size: 20px;
            font-weight: 300;
            color: rgba(57, 76, 108, 0.6);
            line-height: 30px;
        }
        .button {
            margin-top: 40px;
        }
    }
    @media screen and (max-width: ${breakpoints.md}) {
        .modal-window {
            max-width: 300px;
            width: 100%;
            margin: auto;
            padding: 48px 24px;
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
`;

export default props => (
    <Location>
        {locationProps => <Modal {...locationProps} {...props} />}
    </Location>
)
